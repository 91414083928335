<template>
  <div class="group--page">
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-toolbar-title class="headline font-weight-bold">
        Social Media Links
      </v-toolbar-title>
      <v-spacer />
    </v-app-bar>

    <v-divider class="my-5" />

    <router-view />
  </div>
</template>

<script>
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import ControlsMixin from '@/utils/mixins/Controls'

export default {
  name: 'SocialMediaLinksPage',

  mixins: [ControlsMixin],

  components: {
    AppBarNavIcon,
  },

  data() {
    return {
      loading: false,
    }
  },
}
</script>
